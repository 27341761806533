<template>
  <div class="empty-box">
    <img src="https://static.ppkao.com/www/images/empty-data.png" alt="无搜索结果"/>
    <template v-if="$slots.default">
      <slot></slot>
    </template>
    <p v-else>{{ props.title }}</p>

    <el-button v-if="props.showBtn" type="primary" color="#0479FF" class="ai-btn" dark size="large">
     <NuxtLink to="/ai">去问AI {{ countDown }}s</NuxtLink>
    </el-button>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    default: '暂无数据'
  },
  showBtn: {
    type: Boolean,
    default: false
  }
})
const router = useRouter();
const countDown = ref(3);
if (process.client && props.showBtn) {
  const timer = setInterval(()=> {
    if (countDown.value <= 1) {
      clearInterval(timer);
      countDown.value = 0;
      router.push('/ai');
    } else {
      countDown.value --;
    }
  }, 1000)
}
</script>

<style lang="scss">
.empty-box {
  background-color: #fff;
  @include flex-col-center;
  justify-content: center;
  width: 100%;
  height: 358px;
  p {
    margin-top: 30px;
    @include font(var(--title-color), 16px);
    max-width: 80%;
    text-align: center;
    line-height: 24px;
    a {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
  .ai-btn {
    margin-top: 20px;
  }
}
</style>
